import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";

@Component({
  selector: "app-dialog-cancel",
  templateUrl: "./dialog-cancel.component.html",
  styleUrls: ["./dialog-cancel.component.css"],
})
export class DialogCancelComponent implements OnInit {
  rejection_notes: string;
  public form: FormGroup;
  html: boolean = false;
  otherProb = false;
  type: string;

  constructor(
    public dialogRef: MatDialogRef<DialogCancelComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.html = data.html;
    this.type = data.type;
  }

  ngOnInit() {
    this.form = new FormGroup({
      problemType: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      // otherProblem: new FormControl(""),
    });
  }

  getFormValue(): string {
    return this.form.get("problemType")?.value || "";
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  @HostListener("document:keydown.escape", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeDialog();
  }

  cancel() {
    this.dialogRef.close({ data: "no" });
  }

  confirm() {
    this.dialogRef.close({ data: "yes" });
  }

  close() {
    if (this.type === "close") {
      this.dialog.closeAll();
    }
  }
}
