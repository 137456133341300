<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section id="blog" class="section blog-area ptb_50">
      <div class="container">
        <h3 class="py-3ybb">Kategori "{{ category_name }}" ...</h3>
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="sticky-side">
              <aside class="sidebar">
                <h4 class="cat-title bold">
                  <span class="material-icons">filter_alt</span> PENYARINGAN
                </h4>
                <!-- Single Widget -->
                <!-- <div class="single-widget" style="background-color: white; border: 1px solid #3571b6; border-radius: 4px;"> -->
                <!-- Post Widget -->
                <!-- <div class="accordions widget catagory-widget" id="cat-accordion">
                                        <div class="single-accordion">
                                            <h5>
                                                <a role="button" class="collapse show text-uppercase d-block p-3 bold"
                                                    data-toggle="collapse" href="#accordion2" style="border-bottom: 1px solid #3571b6; color: #444;">ON PROMO
                                                </a>
                                            </h5> -->
                <!-- Post Widget Content -->
                <!-- <div id="accordion2" class="accordion-content widget-content collapse show"
                                                data-parent="#post-accordion"> -->
                <!-- Post Widget Items -->
                <!-- <ul class="widget-items">
                                                    <li>
                                                        <div class="filter">
                                                            <input type="checkbox" class="form-check-input"
                                                                id="exampleCheck1">
                                                            <label class="form-check-label pl-1"
                                                                for="exampleCheck1">Voucher Diskon</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="filter">
                                                            <input type="checkbox" class="form-check-input"
                                                                id="exampleCheck1">
                                                            <label class="form-check-label pl-1"
                                                                for="exampleCheck1">Diskon</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="filter">
                                                            <input type="checkbox" class="form-check-input"
                                                                id="exampleCheck1">
                                                            <label class="form-check-label pl-1"
                                                                for="exampleCheck1">Cashback</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                <!-- Single Widget -->
                <div
                  class="single-widget"
                  style="
                    background-color: white;
                    border: 1px solid #3571b6;
                    border-radius: 4px;
                  "
                >
                  <!-- Post Widget -->
                  <div
                    class="accordions widget post-widget"
                    id="post-accordion"
                  >
                    <div class="single-accordion">
                      <h5>
                        <a
                          role="button"
                          class="collapse show text-uppercase d-block p-3 bold"
                          data-toggle="collapse"
                          href="#accordion4"
                          style="border-bottom: 1px solid #3571b6; color: #444"
                          >Batas Harga
                        </a>
                      </h5>
                      <!-- Post Widget Content -->
                      <div
                        id="accordion4"
                        class="accordion-content widget-content collapse show"
                        data-parent="#post-accordion"
                      >
                        <!-- Post Widget Items -->
                        <div class="p-2">
                          <input
                            matInput
                            type="text"
                            [(ngModel)]="minsPrice"
                            value="{{ minPrice }}"
                            [formControl]="form.controls['minPrice']"
                            (input)="onMinChange($event.target.value)"
                            (keyup)="minPriceFilter($event)"
                            class="form-control"
                            placeholder="Harga Terendah"
                          />
                        </div>
                        <div class="p-2">
                          <input
                            matInput
                            type="text"
                            [(ngModel)]="maxsPrice"
                            value="{{ maxPrice }}"
                            [formControl]="form.controls['maxPrice']"
                            (input)="onMaxChange($event.target.value)"
                            (keyup)="maxPriceFilter($event)"
                            class="form-control"
                            placeholder="Harga Tertinggi"
                          />
                        </div>
                        <div class="w-100 text-center">
                          <button class="btn" (click)="search()">
                            Cari Harga
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div class="col-12 col-lg-9">
            <div class="form-group py-2">
              <select
                [(ngModel)]="option"
                class="custom-select"
                id="option"
                name="option"
                required
                (click)="search()"
              >
                <option value="" disabled>Filter Produk</option>
                <option value="terbaru">Terbaru</option>
                <option value="termurah">Termurah</option>
                <option value="termahal">Termahal</option>
                <!-- <option value="terpopuler">Terpopuler</option>
                <option value="rating_tinggi">Rating Tinggi</option> -->
                <option value="refresh">Perbaru Filter</option>
              </select>
              <div class="invalid-feedback">
                Example invalid custom select feedback
              </div>
            </div>
            <ng-container *ngIf="products?.length > 0; else elseNoProduct">
              <div class="row">
                <div
                  class="d-none d-md-block col-md-4 col-lg-3 mb-3 pl-1 pr-1"
                  *ngFor="let product of products"
                >
                  <a href="/product?product_id={{ product.product_id }}">
                    <mat-card class="content h-100">
                      <img
                        mat-card-image
                        class="product-card-image"
                        src="{{ product.main_photo_url }}"
                        alt="product image"
                      />
                      <mat-card-content>
                        <div>
                          <p class="over">{{ product.name }}</p>
                        </div>
                        <div>
                          <p
                            class="bold"
                            style="margin-bottom: 0px; font-size: 13px"
                          >
                            Rp {{ priceTransform(product.min_price) }}
                          </p>
                        </div>
                      </mat-card-content>
                      <mat-card-footer align="end">
                        <div>
                          <p class="desc">
                            <i class="marker fas fa-star"></i
                            ><i class="marker fas fa-star"></i
                            ><i class="marker fas fa-star"></i
                            ><i class="marker fas fa-star"></i
                            ><i class="marker fas fa-star"></i>
                          </p>
                        </div>
                        <div>
                          <p class="desc">
                            <i class="marker fas fa-map-marker-alt"></i>
                            {{ product.city }}
                          </p>
                        </div>
                      </mat-card-footer>
                    </mat-card>
                  </a>
                </div>
              </div>
              <mat-card
                class="d-block .d-sm-none d-md-none mb-3"
                *ngFor="let product of products"
              >
                <a href="/product?product_id={{ product.product_id }}">
                  <div class="col-12">
                    <div class="row d-flex flex-wrap">
                      <div class="col-4 col-md-2 pl-0 pr-0">
                        <img
                          class="align-self-center thumbnail-cart"
                          src="{{ product.main_photo_url }}"
                          alt=""
                        />
                      </div>
                      <div class="col-8 col-md-6">
                        <p class="over">{{ product.name }}</p>
                        <p class="bold">
                          Rp {{ priceTransform(product.min_price) }}
                        </p>
                        <p class="desc text-right">
                          <i class="marker fas fa-star"></i
                          ><i class="marker fas fa-star"></i
                          ><i class="marker fas fa-star"></i
                          ><i class="marker fas fa-star"></i
                          ><i class="marker fas fa-star"></i>
                        </p>
                        <p class="desc text-right">
                          <i class="marker fas fa-map-marker-alt"></i>
                          {{ product.city }}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </mat-card>

              <div class="row">
                <div class="col-12">
                  <!-- Pagination -->
                  <ul class="pagination justify-content-center py-4">
                    <li class="px-1" [ngClass]="{ disabled: pageIndex === 0 }">
                      <a
                        href="javascript:void(0)"
                        aria-label="Previous"
                        (click)="arrowPagination('previous')"
                      >
                        <i class="fas fa-arrow-left"></i>
                      </a>
                    </li>
                    <li
                      class="px-1"
                      *ngFor="let page of totalPageSize; index as i"
                      [ngClass]="{ active: pageIndex === i }"
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="pageIndex = i; numberPagination(i)"
                        >{{ i + 1 }}</a
                      >
                    </li>
                    <li
                      [ngClass]="{
                        disabled: pageIndex === totalPageSize.length - 1
                      }"
                    >
                      <a
                        href="javascript:void(0)"
                        aria-label="Next"
                        (click)="arrowPagination('next')"
                      >
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <!-- <li class="px-1"><a href="#">{{i + 1}}</a></li>
                                        <li  >
                                            <a href="#" aria-label="Next">
                                                <i class="fas fa-arrow-right"></i>
                                            </a>
                                        </li> -->
                  </ul>
                </div>
              </div>
            </ng-container>
            <ng-template #elseNoProduct>
              <div
                class="row justify-content-center"
                style="justify-content: center"
              >
                <div>
                  <img
                    class="shopping-cart-img"
                    style="align-self: center; width: 250px"
                    src="/assets/img/inventory.png"
                  />
                </div>
              </div>
              <div style="text-align: center">
                <div style="color: #1d1d1d">
                  <br />
                  Belum ada produk
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </section>
    <app-footer-one></app-footer-one>
  </div>
</div>
