import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NumberFormatPipe } from "../../../number.pipe";
import { DatePipe } from "@angular/common";
import { switchMap, delay } from "rxjs/operators";

@Component({
  selector: "app-store-profile",
  templateUrl: "./store-profile.component.html",
  styleUrls: ["./store-profile.component.css"],
  providers: [NumberFormatPipe],
})
export class StoreProfileComponent implements OnInit {
  product: any;
  bestSellers: any;
  store_id: number;
  store_name: any = [];
  storeInfos: any;
  params: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe
  ) {
    this.params = this.route.snapshot.queryParamMap;
    this.store_name = this.params.get("store_name");

    // this.service.bestSeller(this.store_id).subscribe(
    //   (data) => {
    //     this.bestSellers = data.products;
    //     // console.log("PersonalStoreComponent-bestseller")
    //     // console.log(data)
    //   },
    //   (e) => {
    //     console.log(e);
    //   }
    // );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit() {
    this.fetchStoreId();
  }

  fetchStoreId() {
    this.service
      .getStoreIdNew(this.store_name)
      .pipe(
        switchMap((data: any) => {
          this.store_id = Number(data.store_id);
          return this.service.storeInfo(this.store_id);
        }),
        switchMap((storeInfo: any) => {
          this.storeInfos = storeInfo;
          // const timeRes = storeInfo.last_online;
          // const currentTime = dayjs();
          // this.timeOnline = currentTime.diff(timeRes, "minute");
          console.log(this.storeInfos);
          const data = {
            store_id: Number(this.store_id),
            page: 0,
            limit: 10,
          };
          return this.service.storeProduct(data);
        }),
        delay(500)
      )
      .subscribe(
        (result) => {
          console.log("Final Result:", result);
          console.log("Store Info:", this.storeInfos);
          // console.log("Store Products:", this.storeProducts);
          // console.log("Reviews:", this.reviews);
        },
        (error) => {
          console.error("Error:", error);
        }
      );
  }
}
