import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute, ParamMap, Router, UrlSegment } from "@angular/router";
import { NumberFormatPipe } from "../../number.pipe";
import {
  DatePipe,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import * as dayjs from "dayjs";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Observable } from "rxjs";
import { concatMap, delay, map, switchMap } from "rxjs/operators";

interface FilterObj {
  price?: {
    minPrice?: number;
    maxPrice?: number;
  };

  option?: string;
  location?: string;
  condition?: string;
}

interface QueryTable {
  // sort: null | Object;
  filters: FilterObj;
  page: number;
  limit: number;
  shop_category?: number;
  category_id?: number;
  store_id?: number;
  // promo?: null | boolean;
}

@Component({
  selector: "app-personal-store",
  templateUrl: "./personal-store.component.html",
  styleUrls: ["./personal-store.component.css"],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    NumberFormatPipe,
  ],
})
export class PersonalStoreComponent implements OnInit {
  // TODO pagination
  // TODO fix mini-sized best seller
  product: any;
  bestSellers: any;
  storeProducts: any;
  storeInfos: any = [];
  store_id: number;
  store_name: any;
  timeOnline: any;
  imageUrl: string;
  reviews: any;
  activeTab: any;
  pageIndex: number = 0;
  limitSize: number = 2;
  totalPageSize: any[] = [];
  reviewPageIndex: number = 0;
  reviewTotalPage: any[] = [];
  params: ParamMap;
  selectedIndex = 0;
  queryTable: QueryTable = {
    filters: {},
    page: this.pageIndex,
    limit: this.limitSize,
  };

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
    private router: Router,
    private location: Location
  ) {
    this.params = this.route.snapshot.queryParamMap;
    this.pageIndex = 0;
    this.store_name = this.params.get("store_name");

    this.fetchStoreId();
    // this.service.bestSeller(this.store_id).subscribe(
    //   (data) => {
    //     this.bestSellers = data.products;
    //     // console.log("PersonalStoreComponent-bestseller")
    //     // console.log(data)
    //   },
    //   (e) => {
    //     console.log(e);
    //   }
    // );

    this.navLinks = [
      {
        label: "Profil Toko",
        link: "store-profile",
        queryParams: { store_name: this.store_name },
        index: 0,
      },
      {
        label: "Produk",
        link: "store-product",
        queryParams: { store_name: this.store_name },
        index: 1,
      },
      // {
      //   label: "Ulasan",
      //   link: "store-review",
      //   queryParams: { store_name: this.store_name },
      //   index: 2,
      // },
    ];

    this.selectedIndex = +this.location.path(true).substr(1) - 1 || 0;
    this.location.subscribe((value: any) => {
      if (value.url) {
        this.selectedIndex = +value.url.substr(1) - 1 || 0;
      }
    });
  }

  fetchStoreId() {
    this.service
      .getStoreIdNew(this.store_name)
      .pipe(
        concatMap((data) => {
          this.store_id = Number(data.store_id);
          return this.service.storeInfo(this.store_id);
        })
      )
      .subscribe(
        (storeInfo) => {
          this.storeInfos = storeInfo;
          this.imageUrl = storeInfo.image_url;
          const timeRes = storeInfo.last_online;
          const currentTime = dayjs();
          this.timeOnline = currentTime.diff(timeRes, "minute");
          // console.log(this.storeInfos);
          const data = {
            store_id: Number(this.store_id),
            page: 0,
            limit: 10,
          };
          return this.service.storeProduct(data);
        },
        (error) => {
          // console.error("Error:", error);
        }
      );
  }

  useDefaultProfileImg() {
    this.imageUrl = "assets/img/store.png";
  }

  fetchReviews(storeId: number, pageIndex: number = 0) {
    this.service.reviewList(null, pageIndex, storeId).subscribe(
      (data) => {
        this.reviews = data;
        this.reviewTotalPage = Array(Math.round(data.total / 10));
        // console.log("PersonalStoreComponent-Review")
        // console.log(data)
      },
      (e) => {
        // console.log(e);
      }
    );
  }

  dateTransform(date: any) {
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm");
  }

  fetchStoreProduct() {
    // console.log(pageIndex)

    this.service.storeProduct(this.queryTable).subscribe(
      (data) => {
        this.storeProducts = data.products;
        this.totalPageSize = Array(Math.round(data.total / 24) + 1);
        // console.log("PersonalStoreComponent-storeProduct")
        console.log("ini yang dapet:", data);
      },
      (e) => {
        // console.log(e);
      }
    );
  }

  formatLastOnline() {
    if (this.timeOnline === 0) {
      return "sedang online";
    } else if (this.timeOnline < 60) {
      return this.timeOnline + " menit yang lalu";
    } else if (this.timeOnline < 1440) {
      return Math.floor(this.timeOnline / 60) + " jam yang lalu";
    } else if (this.timeOnline < 10080) {
      return Math.floor(this.timeOnline / 1440) + " hari yang lalu";
    } else if (this.timeOnline < 40320) {
      return Math.floor(this.timeOnline / 10080) + " minggu yang lalu";
    } else if (this.timeOnline < 483840) {
      return Math.floor(this.timeOnline / 40320) + " bulan yang lalu";
    } else {
      return Math.floor(this.timeOnline / 483840) + " tahun yang lalu";
    }
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === "previous") {
      this.pageIndex = Math.max(0, this.pageIndex - 1);
    } else if (arg === "next") {
      this.pageIndex = Math.min(
        this.totalPageSize.length - 1,
        this.pageIndex + 1
      );
    }
    this.queryTable.page = this.pageIndex;
    this.fetchStoreProduct();
  }

  numberPagination(index: number = 0) {
    this.pageIndex = index;
    this.queryTable.page = this.pageIndex;
    this.fetchStoreProduct();
  }

  // arrowPaginations(arg: string, number: number = 0) {
  //   if (arg === "previous") {
  //     this.reviewPageIndex = this.reviewPageIndex - 1;
  //   } else if (arg === "next") {
  //     this.reviewPageIndex = this.reviewPageIndex + 1;
  //   }
  //   this.fetchReviews(this.store_id, this.reviewPageIndex);
  // }
  // numberPaginations(index: number = 0) {
  //   this.reviewPageIndex = index;
  //   this.fetchReviews(this.store_id, this.reviewPageIndex);
  // }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  inputnumber = 0;

  plus() {
    this.inputnumber = this.inputnumber + 1;
  }
  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
    }
  }

  navLinks: any[];
  activeLinkIndex = -1;

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(
        this.navLinks.find((tab) => tab.link === "." + this.router.url)
      );
    });
  }
}
