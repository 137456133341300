import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import * as dayjs from "dayjs";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import { DialogAlert } from "../../myprofile/detail-profile/detail-profile.component";
import { PRODUCT } from "src/app/components/models/order-management/order-management";
import { DialogCancelComponent } from "../dialog-cancel/dialog-cancel.component";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  detailOrder: any;
  detailAddress: any;
  detailInvoice: any;
  detailBooking: any;
  loadingMode: boolean = false;
  totalWeight: any;
  totalPrice: any;
  trackingDetail: [{}] = [{ status: "Menunggu pembayaran dari pembeli." }];
  seeDetails: boolean = false;
  trackingLoading: boolean = false;
  detailWaybillDisplay: boolean = false;
  details: any;
  address: any;
  subtotal: number = 0;
  invoiceDetail: any;
  buttonAppear: boolean = false;
  interval: any;

  constructor(
    public dialogRef: MatDialogRef<DetailComponent>,
    private dialog: MatDialog,
    private service: ApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PRODUCT,
    private currencyFormat: NumberFormatPipe
  ) {
    this.loadingMode = false;
    console.log(data);
    this.detailOrder = data;

    // if (data.voucher_id) {
    //   this.details.total_discount = data.total_price - data.final_price;
    //   // this.service.voucherDetail(data.voucher_id).subscribe(r => {
    //   //   console.log(r, 'voucher')
    //   //   this.details.voucher_code = r.voucher.code
    //   // })
    // }

    // console.log(this.details.snaphot[0].product_snaphot.process_status)

    // for (let i = 0; i < this.details.invoice_items.length; i++) {
    //   this.details.invoice_items[i].subtotal =
    //     this.details.invoice_items[i].total_price;
    // }

    // if (this.details.status === "Belum Bayar") {
    //   this.trackingDetail.splice(0, 1);
    //   this.trackingDetail.push({
    //     status: "Menunggu pembayaran dari pembeli.",
    //     updated_at: this.details.order_date,
    //   });
    //   this.buttonAppear = true;
    // }

    // if (this.details.status === "Dibatalkan") {
    //   this.trackingDetail.splice(0, 1);
    //   this.trackingDetail.push({
    //     status: this.details.notes,
    //     updated_at: this.details.order_date,
    //   });
    // }

    // if (data.booking_number !== null) {
    //   this.service.bookingDetail(data.booking_number).subscribe((r) => {
    //     this.details.booking = r;
    //   });
    // }

    // this.service
    //   .userAddressDetail(
    //     this.details.invoice_shipping_info.receiver.destination_id
    //   )
    //   .subscribe((r) => {
    //     // console.log(r)
    //     this.address = r;

    //     this.service
    //       .invoiceDetail(this.details.invoice)
    //       .subscribe((invoiceData) => {
    //         // console.log("INVOICE")
    //         // console.log(invoiceData)
    //         this.invoiceDetail = invoiceData;

    //         if (this.details.status === "Diproses") {
    //           this.trackingDetail.splice(0, 1);
    //           if (data.booking_number !== null) {
    //             this.trackingDetail.push({
    //               status:
    //                 "Toko telah mengkonfirmasi pesanan. Pesananmu akan segera dikirim.",
    //               updated_at: dayjs(
    //                 new Date(this.details.booking.package_info[0].createdAt)
    //               ).format("D MMMM YYYY, HH:mm"),
    //             });
    //             this.trackingDetail.push({
    //               status:
    //                 "Pembayaran telah diterima. Menunggu konfirmasi dari toko.",
    //               updated_at: this.invoiceDetail.paid_at,
    //             });
    //             this.trackingDetail.push({
    //               status: "Menunggu pembayaran dari pembeli.",
    //               updated_at: this.details.created_at,
    //             });
    //           } else {
    //             this.trackingDetail.push({
    //               status:
    //                 "Pembayaran telah diterima. Menunggu konfirmasi dari toko.",
    //               updated_at: this.invoiceDetail.paid_at,
    //             });
    //             this.trackingDetail.push({
    //               status: "Menunggu pembayaran dari pembeli.",
    //               updated_at: this.details.created_at,
    //             });
    //           }
    //         }

    //         if (
    //           this.details.status === "Dikirim" &&
    //           this.details.waybill_number !== null
    //         ) {
    //           this.trackingDetail.splice(0, 1);
    //           this.traceWaybillBasic();
    //         }

    //         if (this.details.status === "Selesai") {
    //           this.trackingDetail.splice(0, 1);
    //           this.trackingDetail.push({
    //             status: "Transaksi telah selesai.",
    //             updated_at: this.details.snaphot[0].product_snaphot.updated_at,
    //           });
    //           this.traceWaybillBasic();
    //         }

    //         this.loadingMode = false;
    //       });
    //   });
  }

  statusTransform(status: string) {
    switch (status) {
      case "waiting_confirmation":
        return "Menunggu Konfirmasi";
      case "process":
        return "Diproses";
      case "shipping":
        return "Dikirim";
      case "delivered":
        return "Sampai ditujuan";
      case "canceled":
        return "Dibatalkan";
      case "cancel_request":
        return "Meminta Pembatalan";
      case "done":
        return "Selesai";
    }
  }

  toggleCollapse(invoice: string): void {
    this.seeDetails = !this.seeDetails;
    if (this.seeDetails) {
      this.trackingAWB(invoice);
    }
  }

  previewImage(src: string) {
    let dialog = this.dialog.open(DialogCancelComponent, {
      // width: "310px",
      // height: "230px",
      data: { src: src, type: "image-preview" },
    });
  }

  trackingAWB(invoice: string) {
    this.trackingLoading = true;
    this.service.trackingOrder(invoice).subscribe(
      (r) => {
        // console.log(r)
        this.trackingDetail = r.shipmentTrackingHistories;
        this.trackingLoading = false;
        // console.log(this.trackingDetail)
      },
      (err) => {
        // this.trackingLoading = false
        console.error(err.error.message);
      }
    );
  }

  traceWaybillBasic() {
    // if (!this.waybillForm.valid) {
    //   this.waybillForm.markAllAsTouched()
    //   return
    // }

    this.detailWaybillDisplay = false;
    // this.waybillBasicTableDisplay = false
    this.loadingMode = true;

    var query = this.details.waybill_number.split(/[,\n\s+]+/);
    var newquery = [];
    query.forEach((memberzz) => {
      if (memberzz.length > 5) {
        newquery.push(memberzz);
      }
    });
    // console.log(query)
    this.service.waybillBasic(newquery).subscribe(
      (r) => {
        var i = 1;
        r.forEach((item) => {
          item.index = i;
          i++;
          item.waybill_number = item.waybill_number;
          // console.log(item.waybill_number.substring(0,19))
        });

        // console.log(r)

        for (let i = 0; i < r[0].tracking_element.length; i++) {
          this.trackingDetail.push(r[0].tracking_element[i]);
        }

        this.trackingDetail.push({
          status: "Pesanan dalam proses pengiriman.",
          updated_at: this.details.snaphot[0].product_snaphot.updated_at,
        });
        this.trackingDetail.push({
          status:
            "Toko telah mengkonfirmasi pesanan. Pesananmu akan segera dikirim.",
          updated_at: dayjs(
            new Date(this.details.booking.package_info[0].createdAt)
          ).format("D MMMM YYYY, HH:mm"),
        });
        this.trackingDetail.push({
          status: "Pembayaran telah diterima. Menunggu konfirmasi dari toko.",
          updated_at: this.invoiceDetail.paid_at,
        });
        this.trackingDetail.push({
          status: "Menunggu pembayaran dari pembeli.",
          updated_at: this.details.created_at,
        });

        // console.log(this.trackingDetail)

        // console.log(this.dataSourceWaybillBasic); TGR308UDUS126900E

        this.loadingMode = false;
        // this.waybillBasicTableDisplay = true
      },
      (e) => {
        this.trackingDetail = [{ index: "Not Found" }];

        this.loadingMode = false;
        // this.waybillBasicTableDisplay = true
      }
    );
  }

  formatDate(date: any) {
    return dayjs(date).format("DD MMMM YYYY, HH:mm WIB");
  }

  isLinear = false;

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {}

  payOrder() {
    window.open(this.invoiceDetail.invoice_url);

    this.interval = setInterval(() => {
      this.service.invoiceDetail(this.details.invoice).subscribe((data) => {
        if (data.status === "PAID") {
          this.dialogRef.close({ data: "Paid" });
        }
      });
    }, 10000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  cancelOrder() {
    this.service.cancelInvoice(this.details.invoice).subscribe(
      (r) => {
        // console.log(r)
        this.dialogRef.close({ data: "Cancelled" });
      },
      (e) => {
        console.log(e);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: "Pesanan gagal dibatalkan. Mohon coba beberapa saat lagi.",
          },
        });
      }
    );
  }
}
