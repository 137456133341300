import { Inject, Optional } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import jwt_decode from "jwt-decode";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/api.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [ApiService],
})
export class LoginComponent implements OnInit {
  hide = true;
  form: FormGroup;
  InvalidEmailPassword = [false, false, false];
  InvalidCredential: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService,
    private routes: Router,
    private fb: FormBuilder
  ) {
    this.service.login.subscribe((r) => {
      // console.log("This is login from Cart", r)
      if (r) {
        this.onSubmit();
      }
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });
  }

  onSubmit() {
    const username = this.form.value.username;
    const password = this.form.value.password;

    this.service.getUser(username, password).subscribe(
      (data) => {
        // console.log("asdokaod")
        // console.log(data)
        Swal.fire({
          icon: "success",
          title: "Login Berhasil",
          text: "Selamat Datang di ONMARKET",
          allowOutsideClick: false,
        }).then(() => {
          this.dialogRef.close();
          window.location.reload();
        });

        const decodedToken: any = jwt_decode(data.token);
        //Set Expiration on Local Storage

        localStorage.setItem("username", decodedToken.user);
        localStorage.setItem("jwt", data.token);
        this.service
          .verifyUserExist(localStorage.getItem("jwt"))
          .subscribe(() => {
            console.log("User Verified");
          });

        // console.log(localStorage.getItem('jwt'));

        // this.service.verifyUserExist().subscribe(r => {
        //   console.log('User Verified')
        // })
        // this.routes.navigate(['/'])
        // this.routes.navigate(['/user/profile'])
      },
      (e) => {
        // console.log("eroor coy")

        if (e.error.message == "Account does not exist") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Akun tidak ada!",
          });
        } else if (e.error.message == "Invalid Credential") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Username atau Password salah!",
          });
        } else if (e.error.message == "Account is Disabled") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Akun tidak aktif!",
          });
        }
      }
    );
  }
}
