import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DetailComponent } from "./detail/detail.component";
import { ApiService } from "src/app/api.service";
import { DatePipe } from "@angular/common";
import { NumberFormatPipe } from "src/app/number.pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ConfirmDialogModel,
  DialogConfirmComponent,
} from "./dialog-confirm/dialog-confirm.component";
import { DialogCancelComponent } from "./dialog-cancel/dialog-cancel.component";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit {
  orders: any = [];
  process: any;
  orderTotal: number;
  processTotal: number;
  pageIndex: number = 0;
  limit: number = 5;
  statusOrder: string = "Semua";
  showLoading = false;
  // statusOrderNew: string;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private service: ApiService,
    public datepipe: DatePipe,
    private currencyFormat: NumberFormatPipe
  ) {}

  dateTransform(date) {
    return this.datepipe.transform(date, "dd MMM yyyy");
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  statusTransform(status: string) {
    switch (status) {
      case "waiting_confirmation":
        return "Menunggu Konfirmasi";
      case "process":
        return "Diproses";
      case "shipping":
        return "Dikirim";
      case "delivered":
        return "Sampai ditujuan";
      case "canceled":
        return "Dibatalkan";
      case "cancel_request":
        return "Meminta Pembatalan";
      case "done":
        return "Pesanan Selesai";
    }
  }

  handlePage(e: any) {
    this.pageIndex = e.pageIndex;
    this.limit = e.pageSize;

    console.log("Element page: ", e);

    // console.log(this.statusOrder)
    // console.log(this.pageIndex)

    if (this.statusOrder === "Semua") {
      this.service
        .listOrderHistory(this.pageIndex, this.limit)
        .subscribe((allOrder) => {
          console.log("Fetch data all: ", allOrder);
          this.orders = allOrder.orders;
          this.orderTotal = allOrder.total;
        });
    } else {
      console.log(this.statusOrder);
      console.log("-----------------------------");
      // console.log(this.statusOrderNew);
      this.service
        .listProcess(this.limit, this.pageIndex, this.statusOrder)
        .subscribe(
          (data) => {
            console.log("Fetch data per process: ", data);
            // console.log('LABEL HERE')
            this.orders = data.orders;
            this.orderTotal = data.total;
            // console.log("OrderComponent-listProcess")
            // console.log(data)
          },
          (e) => {
            console.log(e);
          }
        );
    }
  }

  ngOnInit(): void {
    this.showLoading = true;
    this.orderStatus({ tab: { textLabel: "Belum Bayar" } });
  }

  orderStatus(event: { tab: { textLabel: string } }) {
    this.showLoading = true;
    if (event.tab.textLabel === "Semua") {
      this.service.listOrderHistory(this.pageIndex, this.limit).subscribe(
        (allOrder) => {
          this.showLoading = false;
          // console.log(allOrder)
          this.orders = allOrder.orders;
          this.orderTotal = allOrder.total;
        },
        (e) => {
          this.showLoading = false;
          console.log(e);
        }
      );
    } else {
      switch (event.tab.textLabel) {
        case "Belum Bayar":
          this.statusOrder = "unpaid";
          break;
        case "Menunggu Konfirmasi":
          this.statusOrder = "waiting_confirmation";
          break;
        case "Diproses":
          this.statusOrder = "process";
          break;
        case "Dikirim":
          this.statusOrder = "shipping";
          break;
        case "Selesai":
          this.statusOrder = "done";
          break;
        case "Dibatalkan":
          this.statusOrder = "canceled";
          break;
        case "Pengembalian":
          this.statusOrder = "return";
          break;
      }

      console.log(this.statusOrder);
      console.log("-----------------------------");
      this.service
        .listProcess(this.limit, this.pageIndex, this.statusOrder)
        .subscribe(
          (data) => {
            this.showLoading = false;
            // console.log('LABEL HERE')
            this.orders = data.orders;
            this.orderTotal = data.total;
            // console.log("OrderComponent-listProcess")
            // console.log(data)
          },
          (e) => {
            this.showLoading = false;
            console.log(e);
          }
        );
    }
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  cancelOrder(element: any) {
    // console.log(element);
    this.dialog
      .open(DialogCancelComponent, {
        width: "450px",
        height: "300px",
        data: {
          title: "Pembatalan Pesanan",
        },
      })
      .afterClosed()
      .subscribe((rejection_notes) => {
        if (rejection_notes != null && rejection_notes !== "") {
          const dataContent = {
            invoiceNumber: element.invoice,
            reason: rejection_notes,
          };
          console.log(dataContent);
          // console.log(event);
          this.service.cancelInvoice(dataContent).subscribe(
            (res: any) => {
              this.showLoading = false;
              this.openSnackBar(res.message, "");
              this.orderStatus({ tab: { textLabel: element.status } });
            },
            (err) => {
              this.showLoading = false;
              this.openSnackBar(err.error.message, "");
            }
          );
        }
      });
  }

  acceptOrder(element: any) {
    const message = `Apakah anda yakin ingin menyelesaikan pesanan ini?`;

    const dialogData = new ConfirmDialogModel("Konfirmasi Pesanan", message);

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.showLoading = false;
      if (dialogResult == true) {
        this.service.confirmOrder(element).subscribe(
          (res) => {
            this.showLoading = false;
            this.openSnackBar(res.message, "");
            this.orderStatus({ tab: { textLabel: "Dikirim" } });
            console.log("order status: ", "");
          },
          (err) => {
            this.showLoading = false;
            this.openSnackBar("Pesanan gagal diselesaikan", "");
          }
        );
      }
    });
  }

  detail(element: any) {
    console.log("Data Inject: ", element);

    // var detailData = {
    //   snaphot: "",
    //   total_price: 0,
    //   final_price: 0,
    //   insurance_fee: 0,
    //   expedition_price: 0,
    //   expedition_type: "",
    //   invoice: "",
    //   created_at: "",
    //   waybill_number: "",
    //   message: "",
    //   address_id: 0,
    //   voucher_id: 0,
    //   booking_number: "",
    // };

    // for (let i = 0; i < this.orders.length; i++) {
    //   if (this.orders[i].invoice === e.target.id) {
    //     detailData.snaphot = this.orders[i].snaphot_lists;
    //     detailData.total_price = this.orders[i].total_price;
    //     detailData.final_price = this.orders[i].final_price;
    //     detailData.insurance_fee = this.orders[i].insurance_fee;
    //     detailData.expedition_price = this.orders[i].expedition_price;
    //     detailData.expedition_type = this.orders[i].expedition_type;
    //     detailData.invoice = this.orders[i].invoice;
    //     detailData.created_at = this.orders[i].created_at;
    //     detailData.waybill_number = this.orders[i].waybill_number;
    //     detailData.booking_number = this.orders[i].booking_number;
    //     detailData.message = this.orders[i].message;
    //     detailData.address_id = this.orders[i].address_id;
    //     detailData.voucher_id = this.orders[i].voucher_id;
    //   }
    // }

    // console.log(detailData, 'ORDER DETAIL')

    let dialogRef = this.dialog.open(DetailComponent, {
      width: "750px",
      height: "550px",
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data === "Cancelled") {
        this.showLoading = false;
        this.openSnackBar("Pesanan berhasil dibatalkan", "");
      } else if (result.data === "Paid") {
        this.showLoading = false;
        this.openSnackBar("Pembayaran berhasil dilakukan", "");
      }
      if (this.statusOrder === "Semua") {
        this.service
          .listOrderHistory(this.pageIndex, this.limit)
          .subscribe((allOrder) => {
            this.showLoading = false;
            // console.log(allOrder)
            this.orders = allOrder.orders;
            this.orderTotal = allOrder.total;
          });
      } else {
        this.service
          .listProcess(this.limit, this.pageIndex, this.statusOrder)
          .subscribe(
            (data) => {
              this.showLoading = false;
              // console.log('LABEL HERE')
              this.orders = data.orders;
              this.orderTotal = data.total;
              // console.log("OrderComponent-listProcess")
              // console.log(data)
            },
            (e) => {
              this.showLoading = false;
              console.log(e);
            }
          );
      }
    });
  }
}
