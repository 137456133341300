<div class="d-none d-lg-block"></div>
<footer class="footer-area height-emulator text-white">
  <!-- Footer Top -->
  <div class="footer-top pt-2 pb-5">
    <div class="container mt-5">
      <div class="row d-flex">
        <div class="col-12 col-sm-6 col-lg-3 mb-5">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-3">ONMARKET</h3>
            <ul>
              <li class="py-1"><a href="/about-us">Tentang ONMARKET</a></li>
              <li class="py-1"><a href="/help">Bantuan</a></li>
              <li class="py-1"><a href="#">Berita dan Promo</a></li>
              <li class="py-1"><a href="#">FAQ</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <div class="footer-items">
            <!-- Footer Title -->
            <h3 class="footer-title mb-3">LAYANAN USER</h3>
            <ul>
              <li class="py-1">
                <a href="https://seller.onmarket.id/">Penjual</a>
              </li>
              <li class="py-1"><a href="">Pembeli</a></li>
              <!-- <li class="py-1"><a href="#">Mitra</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <ul class="service-list">
            <!-- Single Service -->
            <div class="footer-items">
              <h3 class="footer-title mb-3">INFO PERUSAHAAN</h3>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">location_city</span>
                </div>
                <div class="service-text media-body">
                  <p>
                    Rukan SCBR, Jalan Boulevard Raya Jalan Agung Sedayu City
                    No.2
                  </p>
                  <p>Cakung Barat, Kec. Cakung</p>
                  <p>Jakarta Timur, ID 14140</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">local_phone</span>
                </div>
                <div class="service-text media-body">
                  <p>(021) 50555007</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <span class="material-icons">email</span>
                </div>
                <div class="service-text media-body">
                  <a href="mailto:marketing@onindonesia.id">
                    {{ "marketing@onindonesia.id" }}
                  </a>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mb-4 pl-5">
          <h3 class="footer-title">FOLLOW US</h3>
          <div class="social-icons d-flex mb-3">
            <a
              href="https://www.instagram.com/ondeliveryid/?hl=en"
              target="_blank"
            >
              <i class="fab fa-instagram fa-lg mr-2"></i>
            </a>
            <a href="https://tiktok.com/@ondelivery.id" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="18"
                height="18"
                style="fill: white"
              >
                <path
                  d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
                />
              </svg>
            </a>
            <a href="https://www.facebook.com/Ondeliveryid" target="_blank">
              <i class="fab fa-facebook fa-lg"></i>
            </a>
            <a
              href="https://www.youtube.com/@ondeliveryindonesia"
              target="_blank"
            >
              <i class="fab fa-youtube fa-lg"></i>
            </a>
          </div>
          <p style="font-weight: bold">DOWNLOAD APP</p>
          <div class="button-group store-buttons store-black d-flex flex-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
            >
              <img src="assets/img/google-play-black.png" alt="" />
            </a>
            <a href="#">
              <img
                style="height: 35.88"
                src="assets/img/app-store-black.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div
            class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4"
          >
            <div class="copyright-left">
              &copy; Copyrights 2024 ONMARKET. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
