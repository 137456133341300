<div #panel class="otp-container">
  <div
    style="
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
    "
  >
    <button class="otp-close-button" (click)="closeOtpContainer()">
      &times;
    </button>
  </div>
  <h2 class="otp-title">Verifikasi Kode OTP</h2>
  <h4 class="otp-text">Cek SMS untuk melihat kode OTP</h4>
  <input
    type="text"
    [formControl]="form"
    numbersOnly
    class="otp-input"
    placeholder="Masukkan Kode OTP"
    maxlength="6"
    (input)="onInputChange($event)"
  />
  <div class="otp-counter" id="counter" *ngIf="counter > 0"></div>
  <div class="otp-button-container">
    <button (click)="resendOtp()" class="otp-button" [disabled]="counter > 0">
      Kirim Ulang
    </button>
    <button (click)="submitOtp()" class="otp-button" [disabled]="!form.valid">
      Kirim OTP
    </button>
  </div>
</div>
