<div class="blog">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <section id="blog" class="section blog-area mb-3 pb_50">
      <div class="container">
        <div class="row">
          <div class="col-12 w-100">
            <mat-card class="mt-3">
              <div class="custom-control custom-checkbox">
                <span>
                  <mat-checkbox
                    (change)="masterToggle(); checkAll($event)"
                    [checked]="isAllSelected()"
                    [indeterminate]="
                      dataSelection.hasValue() && !isAllSelected()
                    "
                    [aria-label]="checkboxLabel()"
                  >
                    Pilih semua produk
                  </mat-checkbox>
                </span>
              </div>
              <hr />

              <ng-template [ngIf]="total > 0">
                <div
                  class="col-12"
                  *ngFor="let cart of checklist.subChecklists"
                >
                  <h4>
                    <i class="fas fa-store"></i><span class="ml-2"></span>
                    {{ cart.store_name }}
                  </h4>
                  <div
                    class="custom-control custom-checkbox align-items-middle d-flex flex-column py-3"
                  >
                    <!-- Iterate over products -->
                    <div
                      class="d-flex flex-wrap flex-row col-12"
                      *ngFor="let product of cart?.products"
                    >
                      <div class="col-md-4 col-sm-12">
                        <div class="d-flex flex-row">
                          <div>
                            <mat-checkbox
                              (change)="
                                $event ? dataSelection.toggle(product) : null;
                                handleProductCheckboxChange(product);
                                getSelectedProduct(product);
                                checkAll($event)
                              "
                              [checked]="dataSelection.isSelected(product)"
                              [aria-label]="checkboxLabel(product)"
                              style="padding: 20px 10px 0px 0px"
                            >
                            </mat-checkbox>
                          </div>
                          <div>
                            <a href="{{ product.product_domain }}">
                              <img
                                class="image-costumer mr-3"
                                src="{{ product.main_photo_url }}"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="row m-0">
                            <a href="{{ product.product_domain }}">
                              <p class="col-10" style="margin: 0px">
                                {{ product.name }}
                              </p>
                            </a>
                            <div
                              *ngIf="
                                product.product_variant.variant_name !=
                                'default'
                              "
                            >
                              <p class="col-12" style="margin: 0px">
                                Variasi:
                                {{ product.product_variant.variant_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-12 align-self-center justify-content-center"
                      >
                        <div class="row">
                          <p class="ml-3 m-0 d-md-none d-sm-block">Harga :</p>
                          <p class="m-0">
                            Rp
                            {{
                              priceTransform(
                                product.product_variant.variant_price
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-12 align-self-center">
                        <div class="d-flex flex-row">
                          <div class="exp flex-wrap flex-row">
                            <div class="exp">
                              <div
                                class="btn-varian"
                                type="button"
                                (click)="minus($event)"
                                id="{{ product.cart_id }}"
                              >
                                <i class="fa fa-minus"></i>
                              </div>
                              <div class="form-check-inline">
                                <input
                                  type="number"
                                  class="form-quantities"
                                  id="{{ product.cart_id }}"
                                  [(ngModel)]="product.quantity"
                                  (keyup)="onKeyUp($event)"
                                />
                              </div>
                              <div
                                class="btn-varian"
                                type="button"
                                id="{{ product.cart_id }}"
                                (click)="plus($event)"
                              >
                                <i class="fa fa-plus"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-10 align-self-center justify-content-center"
                      >
                        <button
                          class="btn"
                          [(ngModel)]="deleted"
                          id="{{ product.cart_id }}"
                          (click)="deleteFromCart($event)"
                        >
                          <span class="material-icons">delete</span> Hapus
                        </button>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="0 >= total">
                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <div>
                    <img
                      class="shopping-cart-img"
                      style="align-self: center"
                      src="/assets/img/shopping-basket1.png"
                    />
                  </div>
                </div>
                <div style="text-align: center">
                  <div style="color: #1d1d1d">
                    <br />
                    Keranjangmu kosong! Yuk belanja!
                  </div>
                </div>
              </ng-template>
            </mat-card>
          </div>
        </div>
      </div>
    </section>
    <section class="mb-3">
      <div class="container">
        <h3>
          TIDAK DAPAT DIPROSES (<span>{{ checklist.nonActiveList.length }}</span
          >)
        </h3>
        <div class="row">
          <div class="col-12 w-100">
            <mat-card>
              <ng-template [ngIf]="checklist.nonActiveList.length > 0">
                <div
                  class="col-12"
                  *ngFor="let cart of checklist.nonActiveList"
                >
                  <h4>
                    <i class="fas fa-store"></i><span class="ml-2"></span>
                    {{ cart.store_name }}
                  </h4>
                  <div
                    class="custom-control custom-checkbox align-items-middle d-flex flex-column py-3"
                  >
                    <!-- Iterate over products -->
                    <div
                      class="d-flex flex-wrap flex-row col-12"
                      *ngFor="let product of cart?.products"
                    >
                      <div class="col-md-4 col-sm-12">
                        <div class="d-flex flex-row"></div>
                        <div class="d-flex flex-row">
                          <div>
                            <a href="{{ product.product_domain }}">
                              <img
                                class="image-costumer mr-3"
                                src="{{ product.main_photo_url }}"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="row m-0">
                            <a href="{{ product.product_domain }}">
                              <p class="col-10" style="margin: 0px">
                                {{ product.name }}
                              </p>
                            </a>
                            <div
                              *ngIf="
                                product.product_variant.variant_name !=
                                'default'
                              "
                            >
                              <p class="col-12" style="margin: 0px">
                                Variasi:
                                {{ product.product_variant.variant_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-12 align-self-center justify-content-center"
                      >
                        <div class="row">
                          <p class="ml-3 m-0 d-md-none d-sm-block">Harga :</p>
                          <p class="m-0">
                            Rp
                            {{
                              priceTransform(
                                product.product_variant.variant_price
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-12 align-self-center">
                        {{ product.reason }}
                      </div>
                      <div
                        class="col-md-2 col-sm-10 align-self-center justify-content-center"
                      >
                        <button
                          class="btn"
                          [(ngModel)]="deleted"
                          id="{{ product.cart_id }}"
                          (click)="deleteFromCart($event)"
                        >
                          <span class="material-icons">delete</span> Hapus
                        </button>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-card>
          </div>
        </div>
      </div>
    </section>
    <div class="footer-cart">
      <div class="container">
        <div class="row p-3">
          <div class="col-6 pl-5 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
            />
            <mat-checkbox
              (change)="masterToggle(); checkAll($event)"
              [checked]="isAllSelected()"
              [indeterminate]="dataSelection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              style="color: #3571b6"
              >Pilih semua produk</mat-checkbox
            >
          </div>
          <div class="col-6 d-flex align-items-end flex-column">
            <h4 id="total-cart" style="color: #3571b6">
              Total Harga: Rp {{ priceTransform(totalPrice) }}
            </h4>
            <button
              [disabled]="this.dataSelection.selected.length == 0"
              (click)="checkout()"
              class="btn m-1"
              style="background-color: #3571b6; color: #1d1d1d"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-footer-one></app-footer-one>
  </div>
</div>
