<div mat-dialog-title style="margin: 0">
  <div
    style="display: flex; align-items: center; width: 100%"
    class="pb-3 pt-1"
  >
    <h3 style="font-weight: 500; margin: 0; flex: 1">Detail Transaksi</h3>
    <mat-icon
      style="text-align: end; margin-right: 10px; cursor: pointer"
      mat-dialog-close
      >close</mat-icon
    >
  </div>
  <mat-divider class="py-2"></mat-divider>
</div>

<div mat-dialog-content class="invoice-detail-dialog">
  <div class="invoice-detail">
    <div class="card" style="margin: 0; border: 2px solid #f3f4f5">
      <div class="card-body">
        <div class="row mb-2">
          <div
            [ngClass]="
              detailOrder.status === 'shipping' ||
              detailOrder.status === 'delivered'
                ? 'col-9'
                : 'col'
            "
          >
            <div class="row">
              <div class="col" style="font-size: 16px; font-weight: bold">
                {{ statusTransform(detailOrder.status) }}
              </div>
            </div>
            <div class="row" *ngIf="detailOrder.cancel_request_status">
              <div class="col" style="font-size: 12px">Alasan Pembatalan</div>
              <div
                class="col"
                style="font-size: 12px; text-align: end; font-weight: bold"
              >
                {{ detailOrder.cancel_request.reason }}
              </div>
            </div>
            <div class="row" *ngIf="detailOrder.status === 'canceled'">
              <div class="col" style="font-size: 12px">Alasan Pembatalan</div>
              <div
                class="col"
                style="font-size: 12px; text-align: end; font-weight: bold"
              >
                {{ detailOrder.notes }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                detailOrder.status === 'shipping' ||
                detailOrder.status === 'delivered'
              "
            >
              <div
                class="col"
                style="font-size: 12px; font-weight: normal; text-align: start"
              >
                {{ detailOrder.notes }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                detailOrder.process_due_date &&
                detailOrder.status !== 'delivered'
              "
            >
              <div class="col" style="font-size: 13px; color: red">
                Batas Waktu
              </div>
              <div
                class="col"
                style="font-size: 13px; color: red; text-align: end"
              >
                {{ detailOrder.process_due_date }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                detailOrder.process_due_date &&
                detailOrder.status === 'delivered'
              "
            >
              <div class="col-3" style="font-size: 13px; color: red">
                Selesai Otomatis
              </div>
              <div
                class="col"
                style="font-size: 13px; color: red; text-align: start"
              >
                {{ detailOrder.process_due_date }}
              </div>
            </div>
          </div>
          <div
            class="col"
            *ngIf="
              (detailOrder.status === 'shipping' ||
                detailOrder.status === 'delivered' ||
                detailOrder.status === 'done') &&
              detailOrder.invoice_shipping_info.service_id !== 9
            "
          >
            <div class="d-flex justify-content-end align-items-center">
              <mat-icon
                *ngIf="!seeDetails"
                style="cursor: pointer"
                (click)="toggleCollapse(detailOrder.invoice)"
                >keyboard_arrow_down</mat-icon
              >
              <mat-icon
                *ngIf="seeDetails"
                style="cursor: pointer"
                (click)="toggleCollapse(detailOrder.invoice)"
                >keyboard_arrow_up</mat-icon
              >
            </div>
          </div>
        </div>

        <div
          *ngIf="seeDetails"
          class="tracking-timeline"
          style="
            border: 1px solid #e5e5e5;
            border-radius: 8px;
            margin: 8px auto;
          "
        >
          <!-- <mat-divider class="py-1"></mat-divider> -->
          <div *ngIf="trackingLoading" class="my-3 mx-3">
            <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
          </div>
          <ul *ngIf="!trackingLoading">
            <li *ngFor="let history of trackingDetail">
              <h5 class="font-weight-bold mb-1" style="font-size: 14px">
                {{
                  history.statusTimestamp | date : "dd/MM/yyyy HH:mm:ss" : "UTC"
                }}
              </h5>
              <p class="py-0" style="font-size: 12px">
                {{ history.description }}
              </p>
              <p
                style="font-size: 12px; color: #3571b6; cursor: pointer"
                (click)="previewImage(history.receiverPictureUrl)"
                target="_blank"
                *ngIf="history.receiverPictureUrl"
              >
                Lihat Bukti Pengiriman
              </p>
            </li>
          </ul>
          <!-- <mat-vertical-stepper [linear]="true" *ngIf="!trackingLoading">
            <mat-step *ngFor="let history of trackingDetail">
              <ng-template matStepLabel>
                {{history.statusTimestamp | date:'dd/MM/yyyy HH:mm':'UTC'}}
              </ng-template>
              <div>{{history.description}}</div>
            </mat-step>
          </mat-vertical-stepper> -->
        </div>
        <mat-divider class="pt-2 py-1"></mat-divider>
        <div class="row">
          <div class="col" style="font-size: 12px">No. Invoice</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.invoice }}
          </div>
        </div>
        <div class="row">
          <div class="col" style="font-size: 12px">Catatan</div>
          <div
            class="col"
            style="font-size: 12px; text-align: end; font-weight: bold"
          >
            {{ detailOrder.buyer_note || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col" style="font-size: 12px">Tanggal Pembelian</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.order_date }}
          </div>
        </div>
        <div class="row" *ngIf="detailOrder.payment_date">
          <div class="col" style="font-size: 12px">Tanggal Bayar</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.payment_date }}
          </div>
        </div>
        <div class="row" *ngIf="detailOrder.processed_date">
          <div class="col" style="font-size: 12px">Tanggal Proses</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.processed_date }}
          </div>
        </div>
        <div class="row" *ngIf="detailOrder.shipping_date">
          <div class="col" style="font-size: 12px">Tanggal Dikirim</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.shipping_date }}
          </div>
        </div>
        <div class="row" *ngIf="detailOrder.done_date">
          <div class="col" style="font-size: 12px">Tanggal Selesai</div>
          <div class="col" style="font-size: 12px; text-align: end">
            {{ detailOrder.done_date }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      style="margin-top: 10px; margin-bottom: 5px; border: 2px solid #f3f4f5"
    >
      <div class="card-body">
        <div class="row">
          <div
            class="col"
            style="font-size: 16px; font-weight: bold; padding-bottom: 5px"
          >
            Detail Pesanan
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="card"
              style="margin-top: 10px; margin-bottom: 0"
              *ngFor="let item of detailOrder.invoice_items"
            >
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-3"
                    style="padding: 10px auto; text-align: center"
                  >
                    <a href="{{ item.product_domain }}" target="_blank">
                      <img
                        src="{{ item.photo_url[0] }}"
                        width="100px"
                        height="100px"
                        style="border-radius: 8px"
                        alt="Product Image"
                      />
                    </a>
                  </div>

                  <div class="col">
                    <p style="font-size: 12px; font-weight: bold; margin: 0">
                      {{ item.name }}
                    </p>
                    <p style="font-size: 11px; margin: 0">
                      Variant : {{ item.variant_name }}
                    </p>
                    <p style="font-size: 11px; margin: 0">
                      {{ item.quantity }} x Rp.{{ priceTransform(item.price) }}
                    </p>
                  </div>
                  <div class="col" style="font-size: 12px; font-weight: bold">
                    Rp.{{ priceTransform(item.total_price) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      style="margin-top: 10px; margin-bottom: 5px; border: 2px solid #f3f4f5"
    >
      <div class="card-body">
        <div class="row">
          <div
            class="col"
            style="font-size: 16px; font-weight: bold; padding-bottom: 5px"
          >
            Ringkasan Pembayaran
          </div>
        </div>
        <div class="row">
          <div class="col" style="font-size: 12px">Total Harga</div>
          <div
            class="col"
            style="font-size: 12px; font-weight: bold; text-align: end"
          >
            Rp.
            {{ priceTransform(detailOrder.invoice_payment.merchant_subtotal) }}
          </div>
        </div>
        <div class="row">
          <div class="col" style="font-size: 12px">Biaya Pengiriman</div>
          <div
            class="col"
            style="font-size: 12px; font-weight: bold; text-align: end"
          >
            Rp.
            {{
              priceTransform(detailOrder.invoice_payment.shipping_fee_subtotal)
            }}
          </div>
        </div>
        <div
          class="row"
          *ngIf="detailOrder.invoice_payment.insurance_subtotal > 0"
        >
          <div class="col" style="font-size: 12px">Biaya Asuransi</div>
          <div
            class="col"
            style="font-size: 12px; font-weight: bold; text-align: end"
          >
            Rp.
            {{ priceTransform(detailOrder.invoice_payment.insurance_subtotal) }}
          </div>
        </div>
        <div
          class="row"
          *ngIf="detailOrder.invoice_payment.merchant_discount > 0"
        >
          <div class="col" style="font-size: 12px">Potongan Toko</div>
          <div
            class="col"
            style="font-size: 12px; font-weight: bold; text-align: end"
          >
            - Rp.
            {{ priceTransform(detailOrder.invoice_payment.merchant_discount) }}
          </div>
        </div>
        <mat-divider class="py-1"></mat-divider>
        <div class="row">
          <div class="col-8" style="font-size: 12px; font-weight: bold">
            Total Belanja
          </div>
          <div
            class="col"
            style="
              font-size: 12px;
              font-weight: bold;
              text-align: end;
              color: #3571b6;
            "
          >
            Rp.
            {{ priceTransform(detailOrder.invoice_payment.total_payment) }}
          </div>
        </div>
        <div class="row">
          <div class="col-8" style="font-size: 12px; font-weight: bold">
            Metode Pembayaran
          </div>
          <div
            class="col"
            style="
              font-size: 12px;
              font-weight: bold;
              text-align: end;
              color: #3571b6;
            "
          >
            {{ detailOrder.invoice_payment.payment_method }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      style="margin-top: 10px; margin-bottom: 5px; border: 2px solid #f3f4f5"
    >
      <div class="card-body">
        <div class="row">
          <div
            class="col"
            style="font-size: 16px; font-weight: bold; padding-bottom: 5px"
          >
            Rincian Pengiriman
          </div>
        </div>
        <div class="row">
          <div class="col-3" style="font-size: 12px">Service</div>
          <div class="col-1" style="font-size: 12px">:</div>
          <div class="col" style="font-size: 12px">
            <span *ngIf="detailOrder.invoice_shipping_info.expedition_name"
              >{{ detailOrder.invoice_shipping_info.expedition_name }} -
            </span>
            {{ detailOrder.invoice_shipping_info.service_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-3" style="font-size: 12px">Penerima</div>
          <div class="col-1" style="font-size: 12px">:</div>
          <div class="col" style="font-size: 12px; font-weight: bold">
            {{ detailOrder.invoice_shipping_info.receiver.name }}
            ({{ detailOrder.invoice_shipping_info.receiver.phone_number }})
          </div>
        </div>
        <div class="row">
          <div class="col-3" style="font-size: 12px">Alamat</div>
          <div class="col-1" style="font-size: 12px">:</div>
          <div class="col" style="font-size: 12px; font-weight: bold">
            {{ detailOrder.invoice_shipping_info.receiver.address }},{{
              detailOrder.invoice_shipping_info.receiver.urban
            }},{{ detailOrder.invoice_shipping_info.receiver.district }},{{
              detailOrder.invoice_shipping_info.receiver.city
            }},{{ detailOrder.invoice_shipping_info.receiver.province }}.{{
              detailOrder.invoice_shipping_info.receiver.post_id
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
