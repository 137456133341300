<div class="loading-over" *ngIf="showLoading">
  <mat-spinner></mat-spinner>
</div>

<div
  class="contact-box bg-white rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg horizontal-scroll"
>
  <mat-tab-group
    mat-align-tabs="start"
    (selectedTabChange)="orderStatus($event)"
  >
    <mat-tab label="Belum Bayar">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <div class="mt-2">
                        <p class="text-center highlight-bb2">
                          {{ process.notes }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px; margin-bottom: 10px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                </div>
                <mat-divider class="mt-3 mb-2"></mat-divider>

                <!-- <div class="row d-flex flex-wrap pr-4 py-2">
                      <div
                        class="col-lg-1 col-md-1 col-sm-1"
                        style="padding-right: 0px"
                      >
                        <img
                          class="align-self-center pb-2"
                          style="width: 100px"
                          src="{{ product.photo_url[0] }}"
                          alt=""
                        />
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-6">
                        <h4 style="margin: 0px">
                          {{ product.name }}
                        </h4>
                        <h5 class="text-muted">
                          Variasi : {{ product.variant_name }}
                        </h5>
                        <h5 class="text-muted">Jumlah : {{ product.quantity }}</h5>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                      >
                        <p>Harga Produk : Rp {{ priceTransform(product.price) }}</p>
                      </div>
                    </div> -->

                <!-- <mat-divider class="py-1"></mat-divider> -->
                <div class="row d-flex flex-wrap">
                  <div
                    class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                  >
                    <div class="ml-3" style="padding-bottom: 0px">
                      <h5>
                        Bayar sebelum :
                        <strong style="color: #b6244f">
                          {{ process.expired_date }}</strong
                        >
                      </h5>
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 row align-items-center justify-content-start"
                  >
                    <div
                      class="col-lg-9 col-md-10 ml-1"
                      style="padding-bottom: 0px"
                    >
                      <h4>
                        Total Biaya :
                        <strong>
                          Rp
                          {{ priceTransform(process.total_payment) }}</strong
                        >
                      </h4>
                    </div>
                    <div
                      class="justify-content-end"
                      style="padding-bottom: 0px"
                    >
                      <div class="custom-button">
                        <a
                          style="color: white"
                          target="_blank"
                          href="{{ process.payment_url }}"
                        >
                          Bayar Sekarang
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Menunggu Konfirmasi">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          process.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ process.store_name }}
                        </h4>
                      </a>
                      <div class="mt-2">
                        <p class="text-center highlight-bb">
                          {{ process.notes }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ process.invoice }}"
                      (click)="detail(process)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of process.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="align-self-center pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                    >
                      <div class="ml-2" style="padding-bottom: 0px">
                        <h5>Batas waktu penjual menerima pesanan :</h5>
                      </div>
                      <div>
                        <p>
                          <mat-icon
                            style="
                              color: #b6244f;
                              width: 16px;
                              height: 16px;
                              font-size: 14px;
                            "
                            >access_time</mat-icon
                          >
                          <strong style="color: #b6244f; font-size: 12px">
                            {{ process.process_due_date }}</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12 row align-items-center justify-content-start"
                    >
                      <div
                        class="col-lg-9 col-md-10"
                        style="padding-bottom: 0px"
                      >
                        <h4>
                          Total Biaya :
                          <strong>
                            Rp
                            {{
                              priceTransform(
                                process.invoice_payment.total_payment
                              )
                            }}</strong
                          >
                        </h4>
                      </div>
                      <div
                        class="justify-content-end"
                        style="padding-bottom: 0px"
                      >
                        <div class="custom-button">
                          <a
                            style="color: white"
                            target="_blank"
                            (click)="cancelOrder(process)"
                          >
                            Batalkan Pesanan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Diproses">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          process.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ process.store_name }}
                        </h4>
                      </a>
                      <div class="mt-2">
                        <p class="text-center highlight-onproses">
                          {{ process.notes }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ process.invoice }}"
                      (click)="detail(process)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of process.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="align-self-center pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                    >
                      <div class="ml-2" style="padding-bottom: 0px">
                        <h5>Batas waktu penjual memproses pesanan :</h5>
                      </div>
                      <div>
                        <p>
                          <mat-icon
                            style="
                              color: #b6244f;
                              width: 16px;
                              height: 16px;
                              font-size: 14px;
                            "
                            >access_time</mat-icon
                          >
                          <strong style="color: #b6244f; font-size: 12px">
                            {{ process.process_due_date }}</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12 row align-items-center justify-content-start"
                    >
                      <div
                        class="col-lg-8 col-md-10"
                        style="padding-bottom: 0px"
                      >
                        <h4>
                          Total Biaya :
                          <strong>
                            Rp
                            {{
                              priceTransform(
                                process.invoice_payment.total_payment
                              )
                            }}
                          </strong>
                        </h4>
                      </div>
                      <div
                        class="col-lg-4 col-md-2"
                        style="padding-bottom: 0px"
                        *ngIf="process.status !== 'cancel_request'"
                      >
                        <div class="custom-button">
                          <a
                            style="color: white"
                            target="_blank"
                            (click)="cancelOrder(process)"
                          >
                            Ajukan Pembatalan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Dikirim">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          process.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ process.store_name }}
                        </h4>
                      </a>
                      <div
                        class="mt-2"
                        *ngIf="process.status == 'shipping'; else elseNot"
                      >
                        <p class="text-center highlight-otw">
                          {{ statusTransform(process.status) }}
                        </p>
                      </div>
                      <ng-template #elseNot>
                        <div class="mt-2">
                          <p class="text-center highlight-otw2">
                            {{ statusTransform(process.status) }}
                          </p>
                        </div>
                      </ng-template>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ process.invoice }}"
                      (click)="detail(process)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of process.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="align-self-center pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row align-items-center justify-content-start"
                    >
                      <div
                        class="col-lg-8 col-md-8 ml-1"
                        style="padding-bottom: 0px"
                      >
                        <h4>
                          Total Biaya :
                          <strong>
                            Rp
                            {{
                              priceTransform(
                                process.invoice_payment.total_payment
                              )
                            }}</strong
                          >
                        </h4>
                      </div>
                      <div class="pl-3 align-items-center justify-content-end">
                        <div
                          class="custom-button"
                          [ngClass]="{
                            disabled: process.status == 'shipping'
                          }"
                          (click)="
                            process.status !== 'shipping' &&
                              acceptOrder(process.invoice)
                          "
                        >
                          Pesanan Diterima
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Selesai">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          process.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ process.store_name }}
                        </h4>
                      </a>
                      <div class="mt-2">
                        <p class="text-center highlight-done">
                          {{ process.notes }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ process.invoice }}"
                      (click)="detail(process)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of process.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="align-self-center pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                    >
                      <div class="ml-2" style="padding-bottom: 0px">
                        <h5>
                          Pesanan diselesaikan pada :
                          <mat-icon style="color: #b6244f; font-size: 20px"
                            >access_time</mat-icon
                          >
                          <strong style="color: #b6244f">
                            {{ process.processed_date }}</strong
                          >
                        </h5>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12 row align-items-center justify-content-end"
                    >
                      <h4>
                        Total Biaya :
                        <strong>
                          Rp
                          {{
                            priceTransform(
                              process.invoice_payment.total_payment
                            )
                          }}</strong
                        >
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Dibatalkan">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let process of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          process.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ process.store_name }}
                        </h4>
                      </a>
                      <div class="mt-2">
                        <p class="text-center highlight-cancel">
                          {{ statusTransform(process.status) }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ process.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ process.invoice }}"
                      (click)="detail(process)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of process.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="align-self-center pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row align-items-center justify-content-end"
                    >
                      <h4>
                        Total Biaya :
                        <strong>
                          Rp
                          {{
                            priceTransform(
                              process.invoice_payment.total_payment
                            )
                          }}</strong
                        >
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Semua">
      <div *ngIf="orderTotal > 0; else elseBlock">
        <div class="py-3">
          <div class="card mb-3" *ngFor="let order of orders">
            <div class="card-body">
              <div class="col-12">
                <div class="d-flex flex-wrap flex-row pb-2">
                  <div
                    class="d-flex flex-column col-9"
                    style="padding-left: 0px"
                  >
                    <div class="row col-12">
                      <a
                        href="/store/store-profile?store_name={{
                          order.store_name
                        }}"
                      >
                        <h4 class="pr-2 pl-2 mt-2" style="font-weight: 500">
                          <i class="fas fa-store"></i><span class="ml-2"></span
                          >{{ order.store_name }}
                        </h4>
                      </a>
                      <div class="mt-2" *ngIf="order.status === 'canceled'">
                        <p class="text-center highlight-cancel">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div
                        class="mt-2"
                        *ngIf="order.status === 'waiting_confirmation'"
                      >
                        <p class="text-center highlight-bb">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div class="mt-2" *ngIf="order.status === 'process'">
                        <p class="text-center highlight-otw">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div class="mt-2" *ngIf="order.status === 'done'">
                        <p class="text-center highlight-done">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div class="mt-2" *ngIf="order.status === 'proccess'">
                        <p class="text-center highlight-onproses">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div class="mt-2" *ngIf="order.status === 'unpaid'">
                        <p class="text-center highlight-bb">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                      <div class="mt-2" *ngIf="order.status === 'shipping'">
                        <p class="text-center highlight-otw">
                          {{ statusTransform(order.status) }}
                        </p>
                      </div>
                    </div>
                    <div class="row col-12">
                      <h5 class="gray" style="margin: 0px">
                        Waktu Checkout :
                        <strong>{{ order.order_date }}</strong>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row col-3 d-flex align-items-center justify-content-end"
                    style="padding: 0px"
                  >
                    <a
                      id="{{ order.invoice }}"
                      (click)="detail(order)"
                      style="
                        margin: 0px;
                        color: #b6244f;
                        font-weight: 500;
                        cursor: pointer;
                      "
                      >Detail Belanja</a
                    >
                  </div>
                </div>

                <mat-divider class="mt-3 mb-2"></mat-divider>

                <div *ngFor="let product of order.invoice_items">
                  <div class="row d-flex flex-wrap pr-4 py-2">
                    <div
                      class="col-lg-2 col-md-3 col-sm-3"
                      style="padding-right: 0px"
                    >
                      <img
                        class="pb-2"
                        style="width: 100px"
                        src="{{ product.photo_url[0] }}"
                        alt=""
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <h4 style="margin: 0px">
                        {{ product.name }}
                      </h4>
                      <h5 class="text-muted">
                        Variasi : {{ product.variant_name }}
                      </h5>
                      <h5 class="text-muted">
                        Jumlah : {{ product.quantity }}
                      </h5>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 col-sm-12 pr-2 mt-2 justify-align-right text-right"
                    >
                      <p>
                        Harga Produk : Rp {{ priceTransform(product.price) }}
                      </p>
                    </div>
                  </div>

                  <mat-divider class="py-2"></mat-divider>
                  <div class="row d-flex flex-wrap">
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                      *ngIf="order.status === 'waiting_confirmation'"
                    >
                      <div class="ml-2" style="padding-bottom: 0px">
                        <h5 class="d-flex align-items-center">
                          Batas waktu penjual menerima pesanan :
                          <mat-icon style="color: #b6244f; font-size: 20px"
                            >access_time</mat-icon
                          >
                          <strong style="color: #b6244f">
                            {{ order.process_due_date }}</strong
                          >
                        </h5>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-start"
                      *ngIf="order.status === 'process'"
                    >
                      <div class="ml-2" style="padding-bottom: 0px">
                        <h5 class="d-flex align-items-center">
                          Pesanan akan dikirim sebelum :
                          <mat-icon style="color: #b6244f; font-size: 20px"
                            >access_time</mat-icon
                          >
                          <strong style="color: #b6244f">
                            {{ order.process_due_date }}</strong
                          >
                        </h5>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-md-12 row d-flex align-items-center justify-content-end"
                    >
                      <div class="row" style="padding-bottom: 0px">
                        <p class="pr-2 align-item-middle">Total :</p>
                        <h3
                          class="bold align-item-middle"
                          style="color: #3c3c3c"
                        >
                          Rp
                          {{
                            priceTransform(order.invoice_payment.total_payment)
                          }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePage($event)"
            [length]="orderTotal"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
          ></mat-paginator>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="py-3">
          <div class="card">
            <div class="card-body py-4">
              <div class="col-12 d-flex flex-row flex-wrap text-center">
                Belum ada pesanan
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
