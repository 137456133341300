import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import { AddAddress } from "../accounts/profile/myprofile/address/address.component";
import { ConfirmAddressComponent } from "../accounts/profile/myprofile/address/confirm-address/confirm-address.component";
import { SelectAddressComponent } from "./select-address/select-address.component";
import {
  faBasketShopping,
  faMoneyBill,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelectServiceComponent } from "./select-service/select-service/select-service.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectVoucherComponent } from "./select-voucher/select-voucher.component";
import { DialogAlert } from "../accounts/profile/myprofile/detail-profile/detail-profile.component";
import Swal from "sweetalert2";

declare var popOverOnClick: any;

interface Expedition {
  viewValue: string;
  value: string;
}
// interface Payment {
//   value: string;
//   viewValue: string;
// }

interface ProvinceData {
  country_name: string;
  province_name: string;
  city_name: string;
  sub_district_name: string;
  urban_name: string;
  postal_code: string;
  id: number;
  city_id: number;
}

interface AddressData {
  recipient: string;
  phone: string;
  category: string;
  province: string;
  city: string;
  district: string;
  urban: string;
  post_id: string;
  address_input: string;
  osas_log_id: number;
  address: ProvinceData;
  address_id?: string;
  primary_address: boolean;
}

interface ServiceData {
  cod: boolean;
  desc: string;
  eta: string;
  maxKg: number;
  minKg: number;
  multiplier: number;
  name: string;
  price: number;
  serviceId: number;
  total: number;
}

interface FilterObj {
  field: string;
  keyword: any;
}

interface QueryTable {
  limit: number;
  pageIndex: number;
}

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  isLoading: boolean = false;
  pageEvent: PageEvent;
  primaryAddress: any;
  listOfData: AddressData = {} as AddressData;
  dataSource = new MatTableDataSource<AddressData>([this.listOfData]);
  selectedAddressId: any;
  selectedServiceId: any;
  deliveryPrice: number = 0;
  faBasketShopping = faBasketShopping;
  faTicket = faTicket;
  faMoney = faMoneyBill;
  orderInfo: any[];
  productInfo: any[];
  store_name: string;
  productTotalPrice: number = 0;
  overallPrice: number = 0;
  checkFeeParam = {
    originId: 0,
    destinationId: 0,
    weight: 0,
    dimension: {
      length: 0,
      height: 0,
      width: 0,
    },
  };
  services: ServiceData[] = [];
  selectedExpedition: string = "Pilih Service";
  vouchers = new Array();
  productDetails: any;
  form: FormGroup;
  newVouchers: any;
  testDD: any;
  voucherId: any;
  voucherName: string;
  voucherCode: string;
  insuranceUsed: boolean = false;
  insuranceFee: number = 0;
  discountedPrice: number = 0;

  // NOTE: Global params for select services
  store_id: number;
  city_id: number;

  @ViewChild(MatTable, { static: true }) table: MatTable<any> =
    Object.create(null);
  message: any;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private routes: Router,
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      notes: [null],
      payment: ["", Validators.required],
    });
  }

  ngOnInit() {
    //  if (localStorage.getItem('refreshed') === null) {
    //    localStorage['refreshed'] = true;
    //    this.routes.navigateByUrl("/cart")
    //  } else {
    //    localStorage.removeItem('refreshed');
    //  }
    console.log("Product Info: ", history.state.productInfo);
    this.fetchAddress();
    this.fetchVouchers();

    if (
      history.state.orderInfo != null ||
      history.state.orderInfo != undefined
    ) {
      // this.isLoading = true;
      this.orderInfo = history.state.orderInfo;
      // console.log("order Info: ", this.orderInfo);
      // console.log("order info: ", this.orderInfo);
      this.voucherId = history.state.orderInfo[0].voucher_id;
      this.voucherCode = history.state.orderInfo[0].voucher_code;
      this.voucherName = history.state.orderInfo[0].voucher_name;

      for (let i = 0; i < history.state.orderInfo.length; i++) {
        this.productTotalPrice =
          this.productTotalPrice + this.orderInfo[i].subtotal;
        this.overallPrice =
          this.overallPrice + history.state.orderInfo[i].subtotal;
        this.service
          .productDetail(history.state.orderInfo[i].product_id)
          .subscribe((r) => {
            // console.log("Respons Detail produk", r);
            // console.log(r.vouchers, 'vouchss')
            this.productDetails = r;
            this.store_name = r.store!.name;
            this.store_id = r.store!.store_id;

            if (!r.weight || r.weight === 0) {
              r.weight = 1;
            }
            if (!r.length || r.length === 0) {
              r.length = 1;
            }
            if (!r.width || r.width === 0) {
              r.width = 1;
            }
            if (!r.height || r.height === 0) {
              r.height = 1;
            }
            // console.log("fetchProductDetail", r);
            this.service.userAddressDetail(r.store!.store_id).subscribe(() => {
              // console.log(address)
              // this.checkFeeParam = {
              //   originId: r.store!.osas_log_id,
              //   destinationId: null,
              //   weight: this.checkFeeParam.weight + r.weight,
              //   dimension: {
              //     length: this.checkFeeParam.dimension.length + r.length,
              //     width: this.checkFeeParam.dimension.width + r.width,
              //     height: this.checkFeeParam.dimension.height + r.height,
              //   },
              // };
              // console.log(this.checkFeeParam)

              this.fetchAddress();
              this.isLoading = false;
            });
          });
      }

      this.calcDiscount(this.voucherCode);
    } else {
      // this.routes.navigateByUrl("/cart");
      // this.routes.navigateByUrl("/**")
    }
    // this.overallPrice = this.productTotalPrice + this.deliveryPrice
    // console.log(this.productTotalPrice)
    // console.log(this.orderInfo)
    // console.log()
    // console.log(this.vouchers, 'vouchers')
    // console.log(this.vouchers[0][0], 'vouchers')

    // console.log(this.vouchers.length, 'vouchers')
  }

  fetchAddress() {
    // this.isLoading = true;
    this.service.listUserAddress(this.queryTable).subscribe((r: any) => {
      // this.isLoading = false
      // console.log("Response list address: ", r);
      // console.log('this.fetchAddress')
      // if (r.addresses.length > 0) {
      if (r.addresses.length > 1) {
        for (let i = 0; i < r.addresses.length; i++) {
          if (r.addresses[i].primary_address == true) {
            this.primaryAddress = r.addresses[i];
          }
        }
        if (this.primaryAddress === null || this.primaryAddress === undefined) {
          this.primaryAddress = r.addresses[0];
        }
      } else {
        this.primaryAddress = r.addresses[0];
      }
      // console.log("selected address id: ", this.selectedAddressId);
      if (
        this.selectedAddressId === null ||
        this.selectedAddressId === undefined
      ) {
        this.selectedAddressId = this.primaryAddress;
      } else {
        this.primaryAddress = this.selectedAddressId;
      }
      this.primaryAddress.phone = this.primaryAddress.phone.substring(1);
      // console.log(this.primaryAddress);
      this.checkFeeParam = {
        originId: history.state.productInfo.store!.osas_log_id,
        destinationId: this.primaryAddress.address.id,
        weight: this.checkFeeParam.weight,
        dimension: {
          length: this.checkFeeParam.dimension.length,
          width: this.checkFeeParam.dimension.width,
          height: this.checkFeeParam.dimension.height,
        },
      };

      // console.log("form check fee: ", this.checkFeeParam);
      this.service.checkDeliveryFee(this.checkFeeParam).subscribe((res) => {
        // console.log("Response check fee: ", res);
        this.services = [];
        res.services.map((service: ServiceData) => {
          if (service.total !== 0 && service.serviceId !== 101) {
            this.services.push(service);
          }
        });
        // this.services = res.services
        this.deliveryPrice = this.services[0].total;
        this.selectedServiceId = this.services[0].serviceId;
        this.selectedExpedition = this.services[0].name;
        // console.log(this.services);
        this.overallPrice = this.productTotalPrice + this.deliveryPrice;
        this.isLoading = false;
      });
      // }
      // console.log(this.checkFeeParam)
    });
  }

  voucherList: any;

  fetchVouchers() {
    // this.isLoading = true
    this.service.voucherList().subscribe(
      (data) => {
        // console.log(data)
        this.voucherList = data;
        // this.isLoading = false
      },
      (e) => {
        console.log(e);
        // this.isLoading = false
      }
    );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  openDialog(action: string, obj: any) {
    debugger;
    obj.action = action;

    const dialogRef = this.dialog.open(AddAddress, {
      data: obj,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "Tambah") {
        this.addRowData(result.data);
      }
    });
  }

  openSnackBar(message: any, action: any) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  addRowData(row_obj: AddressData) {
    const newAddressData: AddressData = {
      address: row_obj.address,
      recipient: row_obj.recipient,
      phone: "62" + row_obj.phone.substring(1),
      category: row_obj.category,
      province: row_obj.address.province_name,
      district: row_obj.address.sub_district_name,
      post_id: row_obj.address.postal_code.toString(),
      address_input: row_obj.address_input,
      primary_address: row_obj.primary_address,
      city: row_obj.address.city_name,
      urban: row_obj.address.urban_name,
      osas_log_id: row_obj.address.id,
    };

    this.service
      .addUserAddress(newAddressData, localStorage.getItem("jwt"))
      .subscribe(
        (res) => {
          this.dialog.open(ConfirmAddressComponent);
          // console.log("SUCCESS")
          this.fetchAddress();
          this.fetchVouchers();
        },
        (e) => {
          console.log(e);
          this.dialog.open(DialogAlert, {
            width: "300px",
            data: {
              status: false,
              message: "Gagal menambah alamat. Mohon coba beberapa saat lagi.",
            },
          });
        }
      );

    this.fetchAddress();
    this.fetchVouchers();
    this.table.renderRows();
  }

  pageSize: number = 10;
  pageIndex: number = 0;

  queryTable: QueryTable = {
    limit: this.pageSize,
    pageIndex: this.pageIndex,
  };

  // onQueryParamsChange(params): void {
  //   const { pageSize, pageIndex, sort, filter } = params
  //   this.queryTable.pageSize = this.pageEvent.pageSize
  //   this.queryTable.pageIndex = this.pageEvent.pageIndex
  //   const currentSort = sort.find(item => item.value !== null)
  //   const sortField = (currentSort && currentSort.key) || null
  //   const sortOrder = (currentSort && currentSort.value) || null

  //   if (sortField != null && sortOrder != null) {
  //     this.queryTable.sort = {
  //       field: sortField,
  //       order: sortOrder,
  //     }
  //   } else {
  //     this.queryTable.sort = null
  //   }
  //   this.fetchAddress()
  // }

  // sendParamsServices(){
  //   const data = {
  //     city_id: ,
  //     store_id: Number()
  //   }
  // }

  selectAddress(address_id: string) {
    // console.log("address ID: ", address_id);
    let dialogRef = this.dialog.open(SelectAddressComponent, {
      width: "750px",
      data: address_id,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.selectedAddressId = result;
      // console.log(result);
      // if (result != null || result != undefined) {
      //   this.queryTable.filters = [
      //     {
      //       field: "address_id",
      //       keyword: result,
      //     },
      //   ];
      //   // console.log(this.queryTable.filters)
      // } else {
      //   this.queryTable.filters = [
      //     {
      //       field: "address_id",
      //       keyword: address_id,
      //     },
      //   ];
      //   // console.log(this.queryTable.filters)
      // }
      this.fetchAddress();
      // this.fetchVouchers();
      // this.calcDiscount(this.voucherCode);
    });
  }

  // expeditions: Expedition[] = [
  //   { value: 'ONDELIVERY', viewValue: 'ONDELIVERY' },
  //   {value: 'J&T', viewValue: 'J&T'},
  //   {value: 'JNE', viewValue: 'JNE'},
  //   {value: 'PT POS. Indonesia', viewValue: 'PT POS'}
  // ];

  // selectedPayment: string;
  // payments: Expedition[] = [
  //   {value: 'E-wallet/QRIS', viewValue: 'E-wallet/QRIS'},
  //   {value: 'Transfer Bank', viewValue: 'Transfer Bank'},
  //   {value: 'Kartu Kredit', viewValue: 'Kartu Kredit'},
  //   {value: 'Merchant', viewValue: 'Merchant'},
  // ];

  pickService() {
    // console.log("primary address: ", this.primaryAddress, this.services);
    const dataInject = {
      store_id: Number(this.store_id),
      city_id: this.primaryAddress.address.city_id,
      products: [
        {
          product_id: Number(this.orderInfo[0].product_id),
          quantity: this.orderInfo[0].quantity,
        },
      ],
      selected_service: this.selectedServiceId,
      services: this.services,
    };
    // console.log("Data Inject: ", dataInject);
    // console.log("-------------------");
    // console.log(this.primaryAddress);
    if (this.primaryAddress !== null && this.primaryAddress !== undefined) {
      let dialogRef = this.dialog.open(SelectServiceComponent, {
        width: "500px",
        data: dataInject,
      });

      dialogRef.afterClosed().subscribe((result) => {
        // console.log("RESULT HERE: ", result);
        if (result != null && result != undefined) {
          this.selectedServiceId = result.service_id;
          this.selectedExpedition = result.service_name;
          this.deliveryPrice = result.shipping_fee;
          this.overallPrice = this.productTotalPrice + this.deliveryPrice;
        }
        // this.checkFeeParam = {
        //   originId: this.checkFeeParam.originId,
        //   destinationId: this.primaryAddress.address.id,
        //   weight: this.checkFeeParam.weight,
        //   dimension: {
        //     length: this.checkFeeParam.dimension.length,
        //     width: this.checkFeeParam.dimension.width,
        //     height: this.checkFeeParam.dimension.height
        //   }
        // }
        // this.service.checkDeliveryFee(this.checkFeeParam).subscribe((res) => {
        //   console.log(res)
        //   this.deliveryPrice = res.service_cost
        //   this.overallPrice = this.productTotalPrice + this.deliveryPrice
        // })
        // if (result != null) {
        //   this.queryTable.filters = [
        //     {
        //       field: "address_id",
        //       keyword: result
        //     }
        //   ]
        //   console.log(this.queryTable.filters)
        // } else {
        //   this.queryTable.filters = []
        // }
        // this.fetchAddress()
        this.fetchVouchers();
        this.calcDiscount(this.voucherCode);
      });
    } else {
      this.dialog.open(DialogAlert, {
        width: "300px",
        data: {
          status: false,
          message: "Mohon tambah alamat terlebih dahulu",
        },
      });
    }
  }

  pickVoucher() {
    // var newData = []
    // for ( const key of history.state.orderInfo) {
    //   this.service.productDetail(key.product_id).toPromise().then((r) => {
    //     for (const keyTwo of r.vouchers) {
    //       // console.log(keyTwo, 'leytwo')
    //         newData.push(keyTwo)
    //     }
    //       // console.log(r.vouchers, 'reas')
    //   })
    // }
    let dialogRef = this.dialog.open(SelectVoucherComponent, {
      width: "550px",
      data: {
        voucher: this.voucherList.vouchers,
        currentPrice: this.productTotalPrice,
        selectedVoucher: this.voucherId,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((voucher) => {
      if (voucher) {
        // console.log(voucher)
        if (voucher.voucher.voucher_id !== this.voucherId) {
          this.calcDiscount(voucher.voucher.code);
        }
        this.voucherCode = voucher.voucher.code;
        this.voucherId = voucher.voucher.voucher_id;
        this.voucherName = voucher.voucherName;
        // this.variantDetails.voucher_id = voucher.data
        this.openSnackBar("Voucher berhasil digunakan", "OK");
      }
    });
    this.fetchVouchers();
  }

  calcDiscount(code: string) {
    if (code) {
      this.isLoading = true;
      this.service.calculateDiscount(code, this.productTotalPrice).subscribe(
        (r) => {
          this.isLoading = false;
          // console.log(r)
          this.discountedPrice = r.price_after_discount + this.deliveryPrice;
        },
        (e) => {
          this.isLoading = false;
          console.log(e);
          this.dialog.open(DialogAlert, {
            width: "300px",
            data: {
              status: false,
              message:
                "Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.",
            },
          });
        }
      );
    }
  }

  checkCheckBoxvalue(event: any) {
    this.insuranceUsed = event.checked;
    if (event.checked) {
      this.service
        .insuranceCheckFee(this.selectedServiceId, this.productTotalPrice)
        .subscribe(
          (r) => {
            // console.log(Math.floor(r.ins_cost))
            this.overallPrice = this.overallPrice + Math.floor(r.ins_cost);
            this.discountedPrice =
              this.discountedPrice + Math.floor(r.ins_cost);
            this.insuranceFee = Math.floor(r.ins_cost);
          },
          (e) => {
            console.log(e);
            this.dialog.open(DialogAlert, {
              width: "300px",
              data: {
                status: false,
                message:
                  "Terjadi kesalahan dalam checkout. Mohon coba beberapa saat lagi.",
              },
            });
          }
        );
    } else {
      this.service
        .insuranceCheckFee(this.selectedServiceId, this.productTotalPrice)
        .subscribe(
          (r) => {
            // console.log(Math.floor(r.ins_cost))
            this.overallPrice = this.overallPrice - Math.floor(r.ins_cost);
            this.discountedPrice =
              this.discountedPrice - Math.floor(r.ins_cost);
            this.insuranceFee = 0;
          },
          (e) => {
            console.log(e);
            this.dialog.open(DialogAlert, {
              width: "300px",
              data: {
                status: false,
                message: "Terjadi kesalahan dalam checkout. " + e.error.message,
              },
            });
          }
        );
    }
    // this.calcDiscount(this.voucherCode)
  }

  checkout() {
    // console.log("product detail: ", this.productDetails);

    var orderSummary = {};
    this.isLoading = true;

    if (this.orderInfo[0].cart_id != null) {
      orderSummary = {
        store_id: Number(this.productDetails.store!.store_id),
        message: this.form.value.notes,
        payment: this.form.value.payment,
        cart_id: [],
        variant_id: "",
        quantity: 0,
        delivery_fee: this.deliveryPrice,
        service_name: this.selectedExpedition,
        service_id: this.selectedServiceId,
        orders: this.orderInfo,
        overall_price: this.overallPrice,
        final_price: this.discountedPrice,
        address_id: Number(this.selectedAddressId.address_id),
        voucher_id: this.voucherId,
        insurance_exist: this.insuranceUsed,
        insurance_fee: this.insuranceFee,
      };

      for (let i = 0; i < this.orderInfo.length; i++) {
        orderSummary[0].cart_id.push(this.orderInfo[i].cart_id);
      }

      orderSummary[0].variant_id = this.orderInfo[0].variant_id;
      orderSummary[0].quantity = this.orderInfo[0].quantity;
    } else {
      if (this.selectedAddressId && this.selectedAddressId.address_id) {
        // Access the address_id property
        const addressId = this.selectedAddressId.address_id;
        // Proceed with the rest of your logic
      } else {
        this.isLoading = false;
        Swal.fire({
          title: "Alamat belum dipilih",
          text: "Mohon pilih alamat terlebih dahulu",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }

      orderSummary = {
        store_name: this.store_name,
        shipping: {
          address_id: Number(this.selectedAddressId.address_id)!,
        },
        payment: this.form.value.payment,
        orders: [
          {
            store_id: Number(this.productDetails.store!.store_id),
            buyer_note: this.form.value.notes,
            shipping: {
              insurance: this.insuranceUsed,
              service_id: this.selectedServiceId,
              // delivery_fee: this.deliveryPrice,
              // overal_price: this.overallPrice,
              // insurance_fee: this.insuranceFee,
              // voucher: this.voucherCode,
            },
            products: [
              {
                cart_id: null,
                variant_id: Number(this.orderInfo[0].variant_id),
                quantity: Number(this.orderInfo[0].quantity),
                product_id: Number(this.orderInfo[0].product_id),
              },
            ],
          },
        ],
      };
    }

    // console.log("ordre sum: ", orderSummary);

    // orderSummary.order_info = this.orderInfo
    if (
      this.voucherCode !== null &&
      this.voucherCode !== "" &&
      this.voucherCode !== undefined
    ) {
      if (this.form.value.payment === "COD") {
        this.service.redeemVoucher(this.voucherCode).subscribe(
          (r) => {
            this.isLoading = false;
            this.service.xenditCheckout(orderSummary).subscribe(
              (data) => {
                this.isLoading = false;
                // console.log("response cod", data);
                this.routes.navigateByUrl("/user/order", {
                  state: {
                    orderSummary: orderSummary,
                    invoice: data.invoice,
                    payment_url: data.payment_url,
                  },
                });
              },
              (e) => {
                this.isLoading = false;
                console.log(e);
                this.openSnackBar("Checkout gagal. " + e.error.message, "");
              }
            );
          },
          (e) => {
            this.isLoading = false;
            console.log(e);
            this.openSnackBar("Checkout gagal. " + e.error.message, "");
          }
        );
      } else {
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response cod else", data);
            this.routes.navigateByUrl("/checkout-finish", {
              state: {
                orderSummary: orderSummary,
                invoice: data.invoice,
                payment_url: data.payment_url,
              },
            });
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Harus pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (e.error.message === "") {
            }
            console.log(e);
          }
        );
      }
    } else {
      if (this.form.value.payment === "COD") {
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response else cod: ", data);
            this.routes.navigateByUrl("/user/order", {
              state: {
                orderSummary: orderSummary,
                invoice: data.invoice,
                payment_url: data.payment_url,
              },
            });
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (
              e.error.message ===
              "Must have required property 'orders.0.shipping.service_id'"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih service pengiriman terlebih dahulu",
                ""
              );
            } else {
              this.openSnackBar(
                "Checkout gagal. Mohon coba beberapa saat lagi",
                ""
              );
            }
          }
        );
      } else {
        // console.log("Passing Data", orderSummary);
        this.service.xenditCheckout(orderSummary).subscribe(
          (data) => {
            this.isLoading = false;
            // console.log("response else e-wallet: ", data);
            const orderSummary = {
              store_name: this.store_name,
              shipping: {
                address_id: Number(this.selectedAddressId.address_id)!,
              },
              payment: this.form.value.payment,
              orders: [
                {
                  store_id: Number(this.productDetails.store!.store_id),
                  buyer_note: this.form.value.notes,
                  shipping: {
                    insurance: this.insuranceUsed,
                    service_id: this.selectedServiceId,
                    delivery_fee: this.deliveryPrice,
                    overal_price: this.overallPrice,
                    insurance_fee: this.insuranceFee,
                    // voucher: this.voucherCode,
                  },
                  products: this.orderInfo,
                },
              ],
            };

            const queryParams = {
              orderSummary: orderSummary,
              payment_url: data.payment_url,
              invoice: data.payment_id,
              amount: data.amount,
              payment_id: data.payment_id,
            };

            this.routes.navigate(["/checkout-finish"], {
              queryParams: {
                orderSummary: btoa(JSON.stringify(queryParams)),
              },
            });
          },
          (e) => {
            this.isLoading = false;
            if (
              e.error.message ===
              "'payment' must be equal to one of the allowed values COD,EWallet"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih metode pembayaran terlebih dahulu",
                ""
              );
            } else if (
              e.error.message ===
              "Must have required property 'orders.0.shipping.service_id'"
            ) {
              this.openSnackBar(
                "Checkout gagal. Mohon pilih service pengiriman terlebih dahulu",
                ""
              );
            } else {
              this.openSnackBar(
                "Checkout gagal. Mohon coba beberapa saat lagi",
                ""
              );
            }
          }
        );
      }
    }
  }
}
