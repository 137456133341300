import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import { LoginComponent } from "../accounts/login/login.component";
import { FormControl } from "@angular/forms";
import { NumberFormatPipe } from "../../number.pipe";

import {
  debounceTime,
  tap,
  switchMap,
  finalize,
  catchError,
  map,
  startWith,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.css"],
  providers: [NumberFormatPipe],
})
export class MainHeaderComponent implements OnInit {
  searchQuery: FormControl = new FormControl();
  categories: any[];
  carts: any;
  total: any;
  filteredOptions: any;
  isLoading = false;
  errorMsg: string;
  loggedin: boolean = false;
  loggedinUser: string;
  message: string;
  // autocompleteType: string = "product"; // Set the default type to 'product'

  constructor(
    public dialog: MatDialog,
    private routes: Router,
    private service: ApiService,
    private route: ActivatedRoute,
    private currencyFormat: NumberFormatPipe
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.searchQuery.patchValue(params.get("keyword"));
    });

    // this.service.categoryList().subscribe(
    //   (data) => {
    //     this.categories = data;
    //     console.log(data);
    //     // console.log("Categories-main-header-Component")
    //     // console.log(data)
    //   },
    //   (e) => {
    //     console.log(e);
    //   }
    // );

    this.service.isUpdateCart.subscribe((r) => {
      // console.log("This is Update Cart", r)
      if (r) {
        this.fetchCarts();
      }
    });

    this.service.deletedFromCart.subscribe((r) => {
      // console.log("This is Delete from Cart", r)
      if (r) {
        this.fetchCarts();
      }
    });
  }

  fetchCarts() {
    if (this.loggedin) {
      this.service.cartList().subscribe(
        (data) => {
          this.carts = data.carts;
          this.total = data.total;
          // console.log("CartList-main-header-Component")
          // console.log(data)
        },
        (e) => {
          console.log(e);
        }
      );
    } else {
      this.total = 0;
    }
  }
  // condition() {
  //   this.carts.product_variant.variant_name != 'default'
  // }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  ngOnInit(): void {
    //Test if user status still loggedin
    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.service.verify(localStorage.getItem("jwt")).subscribe(
        (r) => {
          this.loggedinUser = localStorage.getItem("username");
          this.loggedin = true;
          this.fetchCarts();
        },
        (e) => {
          localStorage.clear();
        }
      );
    } else {
      localStorage.clear();
    }

    this.getSearchBoxList();

    if (history.state.signIn != null || history.state.signIn != undefined) {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      id: "LoginDialog",
    });
  }

  getSearchBoxList() {
    this.searchQuery.valueChanges
      .pipe(
        startWith(""),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.service
            .searchBox(value)
            .pipe(
              catchError(() =>
                of({ store: { items: [] }, product: { items: [] } })
              ),
              map((res: any) => {
                const combinedItems = [
                  ...res.store.items.map((item) => ({
                    ...item,
                    type: "store",
                  })),
                  ...res.product.items.map((item) => ({
                    ...item,
                    type: "product",
                  })),
                ];
                return combinedItems;
              })
            )
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        this.filteredOptions = data;
        console.log(this.filteredOptions);
      });
  }

  // logout() {
  //   // console.log(localStorage.getItem('jwt'));
  //   this.service.logOff(localStorage.getItem("jwt")).subscribe(
  //     (data) => {
  //       clearLocalStorage();
  //       window.location.reload();
  //       // this.routes.navigate(['/'])
  //     },
  //     (e) => {
  //       console.log(e);
  //       this.dialog.open(DialogAlert, {
  //         width: "300px",
  //         data: {
  //           status: false,
  //           message: "Gagal logout. Mohon coba beberapa saat lagi.",
  //         },
  //       });
  //     }
  //   );
  // }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  developmentMaintenance() {
    Swal.fire({
      title: "Oops...",
      text: "Fitur ini masih dalam tahap development. Mohon tunggu info lebih lanjut",
      icon: "info",
      confirmButtonText: "OK",
    });
  }

  search() {
    if (this.searchQuery.value != "") {
      this.routes
        .navigate(["/search"], {
          queryParams: {
            keyword: this.searchQuery.value,
            page: 1,
          },
          replaceUrl: true,
        })
        .then(() => {
          window.location.reload();
        });
    }
  }

  navigateBasedOnType(selectedItem: any) {
    if (selectedItem.type === "store") {
    } else {
      // Navigate to /search
      this.routes
        .navigate(["/search"], {
          queryParams: {
            keyword: this.searchQuery.value,
            page: 1,
          },
          replaceUrl: true,
        })
        .then(() => {
          window.location.reload();
        });
    }
  }

  categoryProd(event) {
    this.routes
      .navigate(["/category"], {
        queryParams: {
          category_id: event.category_id,
          page: 1,
        },
        replaceUrl: true,
      })
      .then(() => {
        window.location.reload();
      });
  }

  cartCheck() {
    if (this.loggedin) {
      this.routes.navigate(["/cart"]);
    } else {
      this.openDialog();
    }
  }
}
